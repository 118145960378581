import {AnimatePresence, motion} from "framer-motion";
import { useLocation } from 'react-router-dom';
//images
import EmptyImage from '../images/empty.webp';
import thumbnail from '../images/close-interface/close-interface-thumbnail.webp';
import Image01 from '../images/close-interface/closeInterface01.webp';
import Image02 from '../images/close-interface/closeInterface02.webp';
import Image03 from '../images/close-interface/closeInterface03.webp';
import Image04 from '../images/close-interface/closeInterface04.webp';
import Image05 from '../images/close-interface/closeInterface05.webp';
//components
import WorkEnd from "../components/WorkEnd";

const transition = { duration: 1.0, ease: [0.6, 0.01, 0.1, 0.9] }; 


const Nissan = () => {

  const location = useLocation();
  console.log(location.state03);

  return (
     <div className='work-detail-page'>
      <AnimatePresence initial={true}> 

        <motion.img 
        initial={{
          width: location.state.thumbnailState03.width, 
          x: (location.state.thumbnailState03.offsetLeft),
          y: (location.state.thumbnailState03.offsetTop) / 2.15, //ここの値をよう調整
        }}

        animate={{
          width:"100%", 
          x:0,
          y:0,
          transition: { delay: 0, ...transition }
        }} 

        onAnimationStart={()=> document.body.classList.add("overflow-hidden")}
        onAnimationComplete={()=> document.body.classList.remove("overflow-hidden")}
        
        className='hero-img' 
        src= {thumbnail} 
        alt='test-images'/>

        <motion.div 
        initial={{
          opacity: 0,
        }}

        animate={{
          opacity:100,
          transition: { delay: 0.5, ...transition }
        }}
        className='work-contents'>
          <div className='container'>
           <div className="contents-wrapper">

              {/* ↓Title Section */}
            <div className='title-section'>
              <h1>Close Interface</h1>
              <div className='info'>
                <h4>UX / UI / Product</h4>
                <h4>制作日：2020年</h4>
              </div>
            </div>
            <p className='work-text'>車の中心を貫く形で存在する新しいセンターコンソールとディスプレイに表示されるUIとインタラクションのコンセプトモデルの作成を行いました。</p>
            <p className="work-text">WHYー<br/>車内という環境下において誰もが平等に車の情報を操作できるようになり、コミュニケーションも円滑になるような関係性を作るインターフェイスを目指した。</p>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image01} className='huge-img' alt="Ceoncept Sketch"/>
             <h3>ミニバンの車内における新しいコミュニケーションの形を提案</h3>
             <p className="work-text">大勢の人と旅や移動をする際に使われるミニバンという車において運転手が疎外感を感じやすいという意見がインタビューによりわかりました。この課題を解決するために、運転手以外も車のインターフェイスに触れることができ、それが運転手との会話のキッカケにもなるような体験を提案しました。</p>
             </div>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image02} className='huge-img' alt="Ceoncept Sketch"/>
             <img src={Image03} className='huge-img' alt="Ceoncept Sketch"/>
             <h3>簡易プロトタイプで検証</h3>
             <p className="work-text">アイデアを検証するためにダンボールと紙を使ったラピッドプロトタイプによる体験の検証と車内のスケールモデルを作り実際にデバイスが車内に設置可能かを検証しました。</p>
             </div>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image04} className='huge-img' alt="UI Ceomcept Image"/>
             <img src={Image05} className='huge-img' alt="Hardwear Rendering"/>
             <h3>デバイス上で扱われるアプリケーションのUIとデバイスのデザイン</h3>
             <p className="work-text">提案したデバイス上で使われると想定される基本的なアプリケーションの目的地設定画面と音楽の設定画面に関するUIを制作しました。</p>
             </div>

            
          
           </div>
          </div>


        </motion.div>
       </AnimatePresence>
      <WorkEnd />
    </div>


  )
}


export default Nissan
