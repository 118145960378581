import React, { useState, useRef, useEffect, useCallback} from 'react'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
//Components
import UseScroll from '../components/UseScroll';
import Thumbnail from '../components/Thumbnail';
import SectionTitle from '../components/SectionTitle';
//thumbnail images
import profile from '../images/profile/profile.webp';
import RiumThumbnail from '../images/rium/rium-thumbnail.webp';
import ToothChartThumbnail from '../images/tooth-chart/tooth-chart-thumbnail.webp';
import CloseInterfaceThumbnail from '../images/close-interface/close-interface-thumbnail.webp'

// This compornent has function of showing each work thumbnails and links.


function HomePage() {
  

  const elm = useRef(null);
  const elm02 = useRef(null);
  const elm03 = useRef(null);
  
  const [state, setState] = useState({
    width: 0,
    offsetLeft: 0,
    offsetTop: 0
  });
  
  const [state02, setState02] = useState({
    width: 0,
    offsetLeft: 0,
    offsetTop: 0
  }); 

  const [state03, setState03] = useState({
    width: 0,
    offsetLeft: 0,
    offsetTop: 0
  });
  
  
  //Get Scroll Number
  const [scrollPosition, setScrollPosition] = useState(0);





//Get thumbnail state

  useEffect(() => {
    if (elm.current) {
        const { clientWidth: thmbnaiilWidth, offsetLeft:thumbnailOffestLeft, offsetTop:thumbnailOffsetTop  } = elm.current;

        const PositionUp = () => {
          setScrollPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", PositionUp);
        PositionUp()

        setState({ width: thmbnaiilWidth, offsetLeft: thumbnailOffestLeft, offsetTop:(thumbnailOffsetTop - scrollPosition)}); //初期値が渡されてしまう。
        return () => window.removeEventListener("scroll", PositionUp);
    }
  }, [elm]); 

  console.log(scrollPosition)

  useEffect(() => {
    if (elm02.current) {
        const { clientWidth: thmbnaiilWidth, offsetLeft:thumbnailOffestLeft, offsetTop:thumbnailOffsetTop  } = elm02.current;
        setState02({ width: thmbnaiilWidth, offsetLeft: thumbnailOffestLeft, offsetTop:thumbnailOffsetTop });
    }
  }, [elm02]); 

  useEffect(() => {
    if (elm03.current) {
        const { clientWidth: thmbnaiilWidth, offsetLeft:thumbnailOffestLeft, offsetTop:thumbnailOffsetTop  } = elm03.current;
        setState03({ width: thmbnaiilWidth, offsetLeft: thumbnailOffestLeft, offsetTop:thumbnailOffsetTop });
    }
  }, [elm03]); 




  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    onAnimationComplete={()=> document.body.classList.remove("overflow-hidden")}
    
    className='home-page'>

        <div className='container'>
        <SectionTitle title={'Works'} />

          <motion.div className='list-container'>

            {/* <div className='thumbnails' ref={elm}>
              <Link to = {{ pathname: '/template'}} state = {{ thumbnailState: state }} onClick={checkClick}>
                <Thumbnail thumbnailImage={EmptyImage} workTitle={'Work Title'} category={'Category'}/> 
              </Link>
            </div> */}

            <div className='thumbnails' ref={elm}>
              <Link to = {{ pathname: '/rium'}} state = {{ thumbnailState: state }} >
                <Thumbnail thumbnailImage={RiumThumbnail} workTitle={'Rium'} category={'Product'}/> 
                </Link>
            </div>

            <div className='thumbnails' ref={elm02}>
              <Link to = {{ pathname: '/toothchart'}} state = {{ thumbnailState02: state02 }} >
                <Thumbnail thumbnailImage={ToothChartThumbnail} workTitle={'Tooth Chart'} category={'Product / Prototype'}/> 
                </Link>
            </div>

            <div className='thumbnails' ref={elm03}>
              <Link to = {{ pathname: '/close-interface'}} state = {{ thumbnailState03: state03 }} >
                <Thumbnail thumbnailImage={CloseInterfaceThumbnail} workTitle={'Close Interface'} category={'UX / UI / Product'}/> 
                </Link>
            </div>

          </motion.div>

        <SectionTitle title={'About'} />

            <div className='thumbnails' >
              <Link to = {{ pathname: '/about'}} >
                <Thumbnail thumbnailImage={profile} workTitle={'Designer Proflie'} category={''}/> 
                </Link>
            </div>
          </div >

    </motion.div>
  )
  

}

export default HomePage
