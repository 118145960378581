import React from 'react'
import { Link } from 'react-router-dom';
import {Link as Scroll} from 'react-scroll'

const WorkEnd =(props)=> {

    return (
        <div className="work-end">
         <hr />
          <div  className="container">
           <div className='sectionTitle'>
              <Link to = {{ pathname: '/'}} >
                <div className='arrow-and-text'>
                   <svg className='st0' x="0px" y="0px" viewBox="0 0 307.1 44" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path  d="M0,22L24,7.7v28.6L0,22z"/>
                        <path  d="M47.5,43.4h10.7c6.8,0,10.4-3.6,10.4-10.3v-3.7c0-4.6-1.4-7.8-5.1-9.1v-0.1c3-1.2,4.4-4,4.4-8.5V10
                          c0-6.7-3-10-10.1-10H47.5V43.4z M54.4,17.7V6.2h3.3c2.4,0,3.5,1.4,3.5,4.5v2.4c0,3.5-1.5,4.6-4.1,4.6H54.4z M54.4,37.2V23.9h3
                          c3.1,0,4.4,1.2,4.4,5.3v3.8c0,3.2-1.2,4.3-3.5,4.3H54.4z M91.5,43.4c-0.4-1.8-0.7-4.7-0.7-9.1V21.6c0-6.9-2.6-10.4-9.2-10.4
                          c-6.1,0-9.2,3.5-9.2,9.7v0.8h6.2v-1.2c0-2.4,0.9-3.7,3-3.7c1.9,0,2.7,1.1,2.7,3.6V21c0,1.7-0.6,2.3-5.1,4.5
                          c-5.6,2.7-7.6,5.6-7.6,10.5c0,4.9,2.7,7.9,6.9,7.9c3.2,0,5.5-1.6,6.7-4.3c0.1,2,0.3,3.3,0.5,3.7H91.5z M80.8,38.4
                          c-1.9,0-2.8-1.2-2.8-3.4c0-2,1-3.7,3-4.9c1.7-1.1,2.7-1.7,3.2-2.4v5.4C84.2,36.6,83,38.4,80.8,38.4z M104.8,44
                          c6.3,0,9.4-4,9.4-10.2v-2.5h-6.3v3.2c0,2.4-0.8,3.7-3,3.7c-2.2,0-3-1.4-3-3.7V20.7c0-2.4,0.8-3.7,3-3.7c2.2,0,3,1.4,3,3.7v2.5h6.3
                          v-1.9c0-6.2-3-10.2-9.4-10.2c-6.3,0-9.6,3.7-9.6,10.2v12.5C95.2,40.3,98.5,44,104.8,44z M132.5,43.4h7.1l-7.7-20l7.3-11.7h-7
                          L124.8,25V0h-6.6v43.4h6.6v-9.1l2.6-4.2L132.5,43.4z M165.7,17.4v-5.6H161V3.2h-5.6l-0.6,8.6h-3.9v5.6h3.6v19
                          c0,5.1,2.5,7.7,7.8,7.7c1.4,0,2.5-0.2,3.6-0.6v-5.6c-0.8,0.2-1.6,0.4-2.2,0.4c-1.9,0-2.5-0.7-2.5-2.7V17.4H165.7z M178.4,44
                          c6.2,0,9.7-3.7,9.7-10.3V21.5c0-6.6-3.5-10.3-9.7-10.3s-9.7,3.7-9.7,10.3v12.3C168.7,40.3,172.2,44,178.4,44z M178.4,38.2
                          c-2.4,0-3.1-1.6-3.1-3.8V20.8c0-2.2,0.7-3.8,3.1-3.8s3.1,1.7,3.1,3.8v13.5C181.5,36.6,180.8,38.2,178.4,38.2z M202.5,43.4h6.8V23.9
                          h7.3v19.5h6.8V0h-6.8v17.7h-7.3V0h-6.8V43.4z M237.6,44c6.2,0,9.7-3.7,9.7-10.3V21.5c0-6.6-3.5-10.3-9.7-10.3s-9.7,3.7-9.7,10.3
                          v12.3C227.9,40.3,231.4,44,237.6,44z M237.6,38.2c-2.4,0-3.1-1.6-3.1-3.8V20.8c0-2.2,0.7-3.8,3.1-3.8s3.1,1.7,3.1,3.8v13.5
                          C240.7,36.6,239.9,38.2,237.6,38.2z M276.3,11.2c-2.8,0-5.1,1.6-6.2,4.2c-1.1-2.9-3.3-4.2-6.3-4.2c-2.9,0-5.1,1.6-6.2,4.3l-0.1-3.7
                          h-5.7v31.6h6.6V22.1c0-3.7,1.4-5.1,3.3-5.1c2.1,0,2.7,1.7,2.7,3.8v22.6h6.6V22.1c0-3.7,1.4-5.1,3.3-5.1c2.1,0,2.7,1.7,2.7,3.8v22.6
                          h6.6v-23C283.3,13.9,280.7,11.2,276.3,11.2z M297.3,11.2c-6.3,0-9.7,3.7-9.7,10.3v12.3c0,6.6,3.4,10.3,9.7,10.3
                          c6.3,0,9.7-3.7,9.7-10.3v-0.6h-6.3v1.2c0,2.8-1.2,3.8-3.3,3.8c-2.2,0-3.3-1.1-3.3-3.8V29h12.9v-7.6
                          C307.1,14.9,303.7,11.2,297.3,11.2z M294.2,23.8v-3c0-2.8,1.2-3.8,3.2-3.8s3.2,1.1,3.2,3.8v3H294.2z"/>
                      </g>
                    </svg>  
                </div>
              </Link>
          </div> 
         </div>
        </div>
    );
  }

  export default WorkEnd