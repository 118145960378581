import React from 'react'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
//components
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollTop';
//pages
import Rium from './pages/Rium';
import ToothChart from './pages/ToothChart';
import Nissan from './pages/Nissan';
import HomePage from './pages/HomePage';
import About from './pages/About';
//style
import './App.css';



function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <Header id="top"/>
        <Routes>
           <Route path='/' element={<HomePage />} />
             {/* <Route path='/template' element={<Template />} /> */}
             <Route path='/rium' element={<Rium />} />
             <Route path='/toothchart' element={<ToothChart />} />
             <Route path='/close-interface' element={<Nissan />} />
             <Route path='/about' element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;