import React from 'react'
import logo from '../images/main.svg'
import { Link } from 'react-router-dom';

const Header =(props)=> {
  return (
    <header>
      <div  className="container">
        <div className="logo">
          <Link to="/">
           <img src={logo} alt={"logo"}/> 
          </Link>
        </div> 
      </div>
    </header>
  );
}

export default Header