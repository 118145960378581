import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='container'>
            <p>©︎Hironori Aihara  2023</p>
        </div>
    </div>
  )
}

export default Footer