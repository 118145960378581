import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'


function ScrollTop() {
    const { pathname } = useLocation();
    
    useEffect(() => {

        if({pathname} === '/about'){
            window.scrollTo({
                top: 0,
                left: 0,
                
              });     
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });     
        }
             
        
    }, [pathname]);


    
    return null;
}

export default ScrollTop;