import React from 'react'




const Thumbnail =(props)=> {

    // const { state } = useLocation<MyState>(); //エラーが起きる

    return (
        
            <div className='thumbnails-list'>

                <img 
                className='thumbnailImage' 
                src={props.thumbnailImage} 
                alt={props.altTag} 
                // ref={elm}
                />

                <div className='workTitle'>
                    <h3>{props.workTitle}</h3>
                    <h4>{props.category}</h4>
                </div>
            </div>

    );
  }

export default Thumbnail