import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
//images
import HeroImage from '../images/rium/rium-thumbnail.webp';
import Image01 from '../images/rium/rium-process01.webp';
import Image02 from '../images/rium/rium-process02.webp';
import Image03 from '../images/rium/rium-process03.webp';
import Image04 from '../images/rium/rium-concept01.webp';
import Image05 from '../images/rium/rium-concept02.webp';
import Image06 from '../images/rium/rium-concept03.webp';
//components
import WorkEnd from "../components/WorkEnd";

const transition = { duration: 1.0, ease: [0.6, 0.01, 0.1, 0.9] }; 


const Rium = () => {

  const location = useLocation();
  console.log("スクロール数は" + location.state.thumbnailState.offsetTop);

  return (
    <div className='work-detail-page'>

        <motion.img 
        initial={{
          width: (location.state.thumbnailState.width), 
          x: (location.state.thumbnailState.offsetLeft),
          y: (location.state.thumbnailState.offsetTop) / 2.15, //ここの値をよう調整
        }}

        animate={{
          width:"100%", 
          x:0,
          y:0,
          transition: { delay: 0, ...transition }
        }} 

        onAnimationStart={()=> document.body.classList.add("overflow-hidden")}
        onAnimationComplete={()=> document.body.classList.remove("overflow-hidden")}
        
        className='hero-img' 
        src= {HeroImage} 
        alt='test-images'/>

        <motion.div 
        initial={{
          opacity: 0,
        }}

        animate={{
          opacity:100,
          transition: { delay: 0.5, ...transition }
        }}
        className='work-contents'>

          <div className='container'>
            <div className='title-section'>
              <h1>Rium</h1>
              <div className='info'>
                <h4>Product</h4>
                <h4>制作日：2018年</h4>
              </div>
         </div>

            <p className='work-text'>ポップコーンのような１粒使い切りの石鹸。固形石鹸を電子レンジで加熱するとスポンジのように膨らむという性質を利用した泡立ちやすい石鹸です。</p>
            <p className="work-text">WHYー<br/>固形石鹸をより便利なものにできないか？という疑問からのこのプロジェクトは始まりました。</p>
            <p className="work-text">現在は石鹸といえば液体のものが主流になっています。しかし、洗浄力は固形石鹸の方が液体石鹸に比べて強いと言われています。本来であれば固形石鹸の方を使う方が良いのですが利便性の観点で液体石鹸の方が今の人の生活スタイルにはあっていると言えます。そこで液状石鹸のような利便性も兼ね備えた新しい石鹸の形を発見できないかと考えました。</p>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image01} className='huge-img' alt="test"/>
             <img src={Image02} className='huge-img' alt="test"/>
             <h3>電子レンジで加熱する</h3>
             <p className='work-text'>固形石鹸に対し様々な実験を繰り返していく中で電子レンジで加熱することで膨らむということを発見しました。その際に固形石鹸は中をスポンジのように空洞を作ることから崩れやすく水に溶けやすくなりました。</p>
            </div>

             {/* ↓One image and text Section */}
             <div className="section-wrapper">
             <img src={Image03} className='huge-img' alt="test"/>
             <h3>1粒づつ使い切る形を提案</h3>
             <p className='work-text'>膨らませた石鹸を最終的にはポップコーンのように見立てて、１粒づつ使い切れる石鹸としてデザインしました。</p>
            </div>

             {/* ↓One image and text Section */}
             <div className="section-wrapper">
             <img src={Image04} className='huge-img' alt="test"/>
             <img src={Image05} className='huge-img' alt="test"/>
             <img src={Image06} className='huge-img' alt="test"/>
             <h3>様々なフレーバーでの展開</h3>
             <p className='work-text'>原料ととなる固形石鹸の色や香りを変えることで商品に展開した時に様々なバリエーションは展開できると想定できます。そこでバリエーション展開した際のビジュアルの見せ方も検討しました。</p>
            </div>



         </div>
        </motion.div>
        <WorkEnd />
    </div>


  )
}


export default Rium
