import React from 'react'

const SectionTitle =(props)=> {
    return (
          <div className='sectionTitle'>
            <hr />
            <h1>{props.title}</h1>
          </div> 
    );
  }

  export default SectionTitle