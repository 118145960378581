import {AnimatePresence, motion} from "framer-motion";
import { useLocation } from 'react-router-dom';
//images
import thumbnail from '../images/profile/profile.webp';
//components
import WorkEnd from "../components/WorkEnd";

const transition = { duration: 1.0, ease: [0.6, 0.01, 0.1, 0.9] }; 


const Profile = () => {

  const location = useLocation();
  console.log(location.state04);

  return (
    
     <div className='about-detail-page'>
      <AnimatePresence initial={true}> 

      <motion.div 
      initial={{
        width:'0',
      }}

      animate={{
        width:'auto',
        transition: { delay: 0, ...transition }
      }}

      className="back-ground">
        
        <motion.div 
        initial={{ 
          opacity: 0,
          y: 20,
         }} 

        animate={{ 
          opacity: 1,
          y:0,
          transition: { delay: 1, ...transition } 
        }} 

        onAnimationStart={()=> document.body.classList.add("overflow-hidden")}
        onAnimationComplete={()=> document.body.classList.remove("overflow-hidden")}
        


        className="profile-contents">

          <div className="images">
            <img className='hero-img' src= {thumbnail} alt='test-images'/>
          </div>

          <div className="text">
            <div className="profile-section">

              <div className="name">
               <h2>相原 弘典</h2>
               <p>Hironori Aihara</p>
              </div>
              <p>1998年東京生まれ。<br/>2020年にサレジオ工業高等専門学校デザイン学科プロダクト専攻を卒業し、同年より東京造形大学にてインダストリアルデザインを専攻。インダストリアルからアプリケーションまでサービス起点での提案とアウトプットを行なっている。</p>

              <div className="sns">
                <a href="https://twitter.com/hiro16hiro_ai"  target="_blank" rel="noreferrer">
                  <svg className="sns-icon" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/>
                  </svg>
                </a>

                <a href="https://www.facebook.com/profile.php?id=100003952475060"  target="_blank" rel="noreferrer">
                  <svg className="sns-icon" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24">
                   <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/>
                  </svg>
                </a>

                <a href="https://www.linkedin.com/in/hironori-aihara/"  target="_blank" rel="noreferrer">
                  <svg className="sns-icon" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24">
                   <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/>
                  </svg>
                </a>
               
              </div>

            </div>

            <div className="career-section">
              <h4>経歴</h4>
              <table className="career-list">
                <tr>
                  <th className="right">2014 - 2019</th>
                  <th>サレジオ工業高等専門学校 デザイン科</th>
                </tr>
                <tr>
                  <th className="right">2019 - 2021</th>
                  <th>東京造形大学 デザイン学科 インダストリアルデザイン専攻</th>
                </tr>
                <tr>
                  <th className="right">2020</th>
                  <th>Nissan インターンシップ</th>
                </tr>
                <tr>
                  <th className="right">2020</th>
                  <th>btraxインターンシップ</th>
                </tr>
                <tr>
                  <th className="right">2020</th>
                  <th >カオナビインターンシップ</th>
                </tr>
                <tr>
                  <th className="right">2021 - 2023</th>
                  <th>btrax UI/UX Designerx</th>
                </tr>
                <tr>
                  <th className="right">2023 ~</th>
                  <th >btrax UI/UX Specialist</th>
                </tr>
              </table>
            </div>
          </div>

          </motion.div>
        </motion.div>
        <WorkEnd />
      </AnimatePresence>
    </div>


  )
}


export default Profile