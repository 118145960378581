import {AnimatePresence, motion} from "framer-motion";
import { useLocation } from 'react-router-dom';
//images
import ToothChartThumbnail from '../images/tooth-chart/tooth-chart-thumbnail.webp';
import Image01 from '../images/tooth-chart/toothChart01.webp';
import Image02 from '../images/tooth-chart/toothChart02.webp';
import Image03 from '../images/tooth-chart/toothChart03.webp';
import Image04 from '../images/tooth-chart/toothChart04.webp';
import Image05 from '../images/tooth-chart/toothChart05.webp';
//components
import WorkEnd from "../components/WorkEnd";

const transition = { duration: 1.0, ease: [0.6, 0.01, 0.1, 0.9] }; 


const ToothChart = () => {

  const location = useLocation();
  console.log(location.state02);

  return (
     <div className='work-detail-page'>
      <AnimatePresence initial={true}> 

        <motion.img 
        initial={{
          width: location.state.thumbnailState02.width, 
          x: (location.state.thumbnailState02.offsetLeft),
          y: (location.state.thumbnailState02.offsetTop) / 2.15, //ここの値をよう調整
        }}

        animate={{
          width:"100%", 
          x:0,
          y:0,
          transition: { delay: 0.25, ...transition }
        }} 

        onAnimationStart={()=> document.body.classList.add("overflow-hidden")}
        onAnimationComplete={()=> document.body.classList.remove("overflow-hidden")}
        
        className='hero-img' 
        src= {ToothChartThumbnail} 
        alt='test-images'/>

        <motion.div 
        initial={{
          opacity: 0,
          // display: 'none',
        }}

        animate={{
          opacity:100,
          transition: { delay: 1, ...transition },
        }}
        className='work-contents'>
          <div className='container'>
           <div className="contents-wrapper">

              {/* ↓Title Section */}
            <div className='title-section'>
              <h1>Tooth Chart</h1>
              <div className='info'>
                <h4>Product / Prototype</h4>
                <h4>制作日：2021年</h4>
              </div>
            </div>
            <p className='work-text'>歯ブラシの形をした口内スキャナーで歯の状態を記録できるIoTデバイスです。毎日歯の形状を記録することで歯の異常をデバイスから警告したり、カルテとして歯科医側が使用することも検討しています。</p>
            <p className="work-text">WHYー<br/>私は自信が定期検診へ通う中で、わざわざ通うという行為がとても無駄が多いと感じました。そこで患者にとっても歯科医にとっても効率の良く便利な仕組みを提供できるのではないかと考えました。</p>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image01} className='huge-img' alt="Dentist Research"/>
             <h3>歯科衛生意識と実際の受診率の調査</h3>
             <p className='work-text'>日本は歯科衛生に関して意識が高い国であると言われています。一方で実際に定期検診を受診している人は少なく、特に年齢が上がるにつれてう蝕有病（＝虫歯）などの歯の健康に何かしら問題を抱え歯を失う人が増えいくという結果があります。特に年齢が上がるに連れて定期検診の受信率は下がる傾向にあります。これらの調査を踏まえて更に定期検診に通っていたが辞めてしまった人になぜ辞めてしまったかの調査を行うと、病院への移動や治療時間を手間と考える人が多く挙がりました。</p>
            </div>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image02} className='huge-img' alt="Concept Sketch"/>
             <img src={Image03} className='huge-img' alt="Service Concept Image"/>
             <h3>歯の状態を3Dスキャンを行い遠隔でも定期検診が行えるようにする</h3>
             <p className='work-text'>歯の形状を3Dスキャンしてそのデータを蓄積して比較することで歯石の残りや歯の健康状態などを病院に行かずとも定期検診のような状況を作っていけるのではないかと考えました。また、この情報にはその人の歯磨きの癖や噛み方などの固有の情報も得られるのではないかと考えています。</p>
             <p className="work-text">それらは歯周病のような患者のライフスタイルが密接に関係する病気の治療にもこれらの情報は役立つとされます。</p>
            </div>

            {/* ↓One image and text Section */}
            <div className="section-wrapper">
             <img src={Image04} className='huge-img' alt="UI Mockup"/>
             <img src={Image05} className='huge-img' alt="Photogrammetry Prototype"/>
             <h3>プロトタイプの制作</h3>
             <p className='work-text'>これらのコンセプトを実現するためにまず初めに口内を正確にスキャンできるかが重要になる。そこで今回は口内を小型カメラでさまざまな角度で撮影し、それを３Dデータにするフォトグラメトリの技術を活用したものを作成した。</p>
            </div>

            
           </div>
          </div>


        </motion.div>
       </AnimatePresence>
      <WorkEnd />
    </div>


  )
}


export default ToothChart
